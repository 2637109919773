import React from "react";

import {Box, Grommet, Image, Main, Footer, Text} from 'grommet';
import {BarLoader} from "react-spinners";
import axios from 'axios';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";

import "./style.css";
import SettingsBar from "./components/settingsBar/settingsBar";
import LiveApp from "./components/live/live";
import VODApp from "./components/onDemand/onDemand";
import VodPlayer from  "./components/onDemand/vodPlayer"
import Login from "./components/login/login";

import {GlobalStateProvider} from "./hooks/useGlobalState";

const theme = require('./theme.json');

const AppBar = (props) => (
		<Box
		  tag='header'
		  direction='row'
		  align='start'
		  justify='between'
          className='float-header'
		  pad={{ left: 'medium', right: 'medium', vertical: '5px'}}
		  style={{zIndex: '10', color: '#6ebe44' }}
		  {...props}
		/>
);

class WrappedApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            loading: true,
            user_id: null
        };
    }

    favoritesHandler = (favs) => {
        let f = favs.favorites.split(",");
        this.setState({favs: f});
    };

    componentDidMount() {
        let tkn = localStorage.getItem('tkn');
        if (tkn){
            axios.get("https://thedwellings.tv/frontend/profile", {headers: {'Authorization': `Bearer ${tkn}`}})
                .then( r => {
                    if(r.status === 200){
                        this.setState({loggedIn: true, loading: false, currentApp: <LiveApp favs={this.state.favs}/>, user_id: r.data.result.payload.id});
                    }
                })
        } else{
            this.setState({loading: false});
        }
    }

    render() {
    return (
        !this.state.loading ?
        this.state.loggedIn ?
            <Grommet theme={theme} full>
                <Router>
                    <AppBar>
                        <Image src="https://dwellings.s3.us-east-2.amazonaws.com/dtv_white_app.png" className="top-logo"/>
                        <Box direction={"row"} align={"center"} alignSelf={"center"} gap="medium" responsive>
                            <NavLink to="/live" className="nav-link">Live</NavLink>
                            <NavLink to="/vod" className="nav-link" activeClassName="active">On Demand</NavLink>
                        </Box>
                        <SettingsBar handler={this.favoritesHandler} user={this.state.user_id}/>
                    </AppBar>
                    <Main>
                        <Route exact path={["/", "/live", "/index.html"]}><LiveApp favs={this.state.favs}/></Route>
                        <Route exact path="/vod"><VODApp /></Route>
                        <Route path="/vod/player" component={VodPlayer} />
                        <Footer><Text size="xsmall">&copy; DwellingsTV</Text></Footer>
                    </Main>
                </Router>
            </Grommet>
         : <Login />
    : <Grommet theme={theme} full>
        <Box align="center" margin="medium" fill><BarLoader color="#6ebe44"  width={"30%"} /></Box>
    </Grommet>
    );
  }
};

const App = () => {
    return(
    <GlobalStateProvider>
        <WrappedApp />
    </GlobalStateProvider>
    )
}

export default App;
