import React from "react";
import {Box, Button, Heading, Form, FormField, Anchor, Grommet} from "grommet";
import axios from 'axios';

const theme = require('../../theme.json');

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            now: new Date(),
        };
    }

    apiLogin(e){
        let email = e.value.email;
        let password = e.value.password;
        axios.post('https://thedwellings.tv/frontend/login', {email: email, password: password})
            .then( r => {
                if(r.data.result.token) {
                    localStorage.setItem('tkn', r.data.result.token);
                    window.location.reload();
                }
                else{
                    alert("Wrong user info");
                }
            })
    }

    render() {
        return (
            <Grommet theme={theme} full>
                <Box fill direction={"row-responsive"}>
                    <Box width="1/2" background={{"image": "url(https://dwellings.s3.us-east-2.amazonaws.com/brochure_bg.jpg)", "size":"cover"}} fill>

                    </Box>
                    <Box width="1/2" background="#1c1c1c" fill pad={"medium"}>
                        <Heading level={"2"}>Login</Heading>
                        <Form onSubmit={this.apiLogin}>
                            <FormField
                                fill
                                plain
                                type={"email"}
                                label="Email"
                                name="email"
                            />
                            <FormField
                                fill
                                plain
                                type={"password"}
                                label="Password"
                                name="password"
                            />
                            <Button type={"submit"} label="Login" primary color="brand"/>
                        </Form>
                        <Box margin="medium" direction="row" gap="small" alignSelf="end">
                            <Anchor href="https://thedwellings.tv/register" label="Become a Member" size="xsmall" color="text-weak"/>
                            <Anchor href="https://thedwellings.tv/admin/users/forgot-password" label="Forgot Password?" size="xsmall" color="brand"/>
                        </Box>
                    </Box>
                </Box>
            </Grommet>
        );
    }
}

export default Login;
