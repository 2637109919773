import React from "react";
import Program from "./program";
import { Box, Text, Image } from "grommet";
import useGlobalState from "../../hooks/useGlobalState";

const Channel = (props) => {
    const globalState = useGlobalState();
    const activeStream = globalState.state.app.stream;
    const cn = props.stream === activeStream ? "active-stream" : "";

    const clicked = () => {
        props.handler(props.stream)
    };

    const renderPrograms = () => {
        let programComponents = [];
        let programs = props.programs;
        for(let p in programs){
            programComponents.push(
                <Program key={Math.random()} title={programs[p].programme.title.text} data={programs[p].programme}
                         w_start={props.w_start} w_end={props.w_end}
                         duration={props.duration} cn={cn}
                />
            )
        }
        return programComponents;
    };


    return (
        <Box background={"#1c1c1c"} onClick={clicked} direction="row" flex className={"channel"} focusIndicator={false}>
            <Box background={"dark-1"} border={{"color":"#000", "radius":"10px", "size":"1px"}}  round justify={"center"} align={"center"} width={"75px"} height="xsmall">
                {props.icon ? <Image
                        src={props.icon}
                        className="channelIcon"
                    /> :
                    <Text>{props.title}</Text>
                }
            </Box>
            <Box fill direction="row" overflow="hidden">
                {renderPrograms()}
            </Box>
        </Box>
    )
};

export default Channel;
