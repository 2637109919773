import React, {useEffect} from "react";
import { Close, UserSettings } from "grommet-icons";
import useGlobalState from "../../hooks/useGlobalState"
import axios from 'axios';

import {
    Box,
    Button,
    Grid,
    Heading,
    Layer,
    CheckBox
} from "grommet";

const SettingsBar = (props) => {
    let globalState = useGlobalState();
    let favorites = globalState.state.app.favorites;
    let notification = {
        open: false,
        message: null
    };

    const [open, setOpen] = React.useState(false);

    const onOpen = () => setOpen(true);

    const onClose = () => setOpen(undefined);

    const addItem = (v) => {
        let fav = {favorites: [ ...favorites, v]};
        globalState.updateApp(fav);
    };

    const removeItem = (v) => {
        const index = favorites.indexOf(v);
        if (index > -1) {
            favorites.splice(index, 1);
        }
        let fav = {favorites: [ ...favorites]};
        globalState.updateApp(fav);
    };

    const logout = () => {
        localStorage.removeItem('tkn');
        window.location.reload();
    };

    const saveFavorites = () => {
        axios.post("https://thedwellings.tv/exapi/users/favorites", {favorites: favorites.join(";"), id: props.user })
            .then( r => {notification.open = true; notification.message="Settings Saved"})
    };

    useEffect( () => {
        axios.get("https://thedwellings.tv/exapi/users/favorites", {params: {id: props.user}})
            .then(fav =>  globalState.updateApp({favorites: fav.data.favorites.split(";")}))
            .catch( err => console.log(err))
    }, [props.user]);

    const  channelLabels = [
        {label: "CBS", id:"CBS (WCTV) Tallahassee, FL"},
        {label: "NBC", id:"NBC (WTWC) Tallahassee, FL"},
        {label: "FOX", id:"FOX (WTWC-DT2) Tallahassee, FL"},
        {label: "ABC", id:"ABC (WTXL) Tallahassee, FL"},
        {label: "MeTV", id:"MeTV (WCTV-DT2) Tallahassee, FL"},
        {label: "Circle", id:"Circle (WCTV3) Tallahassee, FL"},
        {label: "PBS", id:"PBS (WFSU) Tallahassee, FL"},
        {label: "Florida", id:"Florida (WFSU-DT2) Tallahassee, FL"},
        {label: "Create", id:"Create (WFSU-DT3) Tallahassee, FL"},
        {label: "Bounce", id:"Bounce (WTXL-DT2) Tallahassee, FL"},
        {label: "Grit", id:"Grit TV (WTXL-DT3) Tallahassee, FL"},
        {label: "Court", id:"Court TV Mystery (WTXL-DT4) Tallahassee, FL"},
        {label: "CHARGE", id:"CHARGE! (WTWC-DT3) Tallahassee, FL"},
        {label: "H&I", id:"H&I (WTLH) Tallahassee, FL"},
        {label: "CW+", id:"CW+ (WTLH-DT2) Bainbridge, GA"},
        {label: "Comet", id:"Comet TV (WTLH-DT3) Tallahassee, FL"},
        {label: "PBS Kids", id:"PBS Kids (WFSU-DT4) Tallahassee, FL"}
    ];

    const renderFavorites = () => {
        return(
            channelLabels.map((c, i) => <CheckBox
                label={c.label} gridArea={i % 3}
                id={c.id}
                    onChange={(event) => favorites.includes(event.target.id) ? removeItem(event.target.id) : addItem(event.target.id)}
                checked={favorites.includes(c.id)}
            />)
        )
    };

    return (
        <Box align="end" justify="center">
            <Button icon={<UserSettings />} onClick={onOpen} />
            {open && (
                <Layer
                    position="right"
                    full="vertical"
                    modal
                    onClickOutside={onClose}
                    onEsc={onClose}
                >
                    <Box
                        background="dark-3"
                        as="form"
                        fill="vertical"
                        overflow="auto"
                        width="medium"
                        pad="medium"
                        onSubmit={onClose}
                    >
                        <Box flex={false} direction="row" justify="between">
                            <Heading level={2} margin="none">
                                Settings & Preferences
                            </Heading>
                            <Button icon={<Close />} onClick={onClose} />
                        </Box>
                        <Box flex="grow" overflow="auto" pad={{ vertical: "medium" }}>
                            <Heading level={5}>Favorite Channels</Heading>
                            <Grid
                                columns={['xsmall', 'xsmall', 'xsmall']}
                                rows={['flex']}
                                gap={{"column":"medium", "row":"5px"}}
                                areas={[
                                    {name: 0, start: [0,0], end: [0,0]},
                                    {name: 1, start: [1,0], end: [1,0]},
                                    {name: 2, start: [2,0], end: [2,0]}
                                ]}
                            >
                                {renderFavorites()}
                            </Grid>
                            <Button
                                type="submit"
                                label="Save"
                                onClick={saveFavorites}
                                primary
                                margin={{"vertical":"small"}}
                            />
                        </Box>
                        <Box flex={false} as="footer" align="start">
                            <Button
                                type="submit"
                                label="Logout"
                                onClick={logout}
                                primary
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
        </Box>
    );
};

export default SettingsBar;
