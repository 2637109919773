import ReactGA from 'react-ga';

class DGA{
    constructor() {
        this.playing = false;
        this.stream = null;
        ReactGA.initialize('UA-127675495-2', {
            debug: false,
            titleCase: false
        });
    }

    /**
     * Track a page view. Use with react-dom
     *
     * @param {string} path
     * @returns {boolean}
     */
    trackPage(path){
        ReactGA.pageview(path);
        return true;
    }

    /**
     * Track a modal view
     *
     * @param {string} path
     * @returns {boolean}
     */
    trackModal(path){
        ReactGA.modalview(path);
        return true;
    }

    /**
     *
     * @param {object} event
     * @returns {boolean}
     */
    trackEvent(event){
        ReactGA.event(event);
        return true;
    }

    /**
     * Start playing a stream and trigger progress events
     *
     * @param {string} stream
     */
    startPlaying(type, stream){
        let event = {
          category: type,
          action: "start",
          label: stream
        };
        if(this.trackEvent(event)){
            this.playing = true;
            this.stream = stream;
        }
    }

    /**
     * Send a progress event every minute
     *
     * @param {string} stream
     */
    whilePlaying(){
        setTimeout( () => {
            if(this.playing){
                let event = {
                    category: "video",
                    action: "progress",
                    label: this.stream
                };
                this.trackEvent(event)
            }
        }, 60000);
    }

    onProgress(type, e) {
        let event = {
            category: type,
            action: "progress",
            label: this.stream +": "+ e.playedSeconds
        };
        this.trackEvent(event)
    }


    /**
     * Stop stream and stop progress events
     *
     * @param {string} stream
     */
    stopPlaying(type, stream){
        let event = {
            category: type,
            action: "stop",
            label: stream
        };
        this.trackEvent(event);
    }
}

export default DGA;
