import React, {useEffect, useState} from "react";
import axios from 'axios';
import Channel from "./channel";
import { Box } from "grommet";
import {BarLoader} from "react-spinners";
import TimeRow from "./timeRow";
import useGlobalState from "../../hooks/useGlobalState";

const url = "https://mereoyssdk.execute-api.us-east-1.amazonaws.com/beta";

const Guide = (props) => {
    let globalState = useGlobalState();
    let live = globalState.state.live;
    const [streams, setStreams] = useState({})
    const duration = window.innerWidth > 1000 ? 120 : 60;
    let refresh_timeout;
    let w_start;

    const guideData = () => {
        return axios.get(url, {params:{duration:duration}}).then( r => r.data);
    };

    const reorderGuide = (guide) => {
        let favorites = props.favs;
        if (favorites && favorites.length > 0) {
            for (let channel in guide) {
                if(favorites.indexOf(guide[channel].props.title) > 0){
                    let c = guide[channel];
                    guide.splice(channel,1);
                    guide.unshift(c);
                }
            }
        }
        return guide;
    };

    const renderGuide = (gdata) => {
        globalState.updateLive({w_start:gdata.window_start});
        w_start = gdata.window_start;
        let channelComponents = [];
        for(let c in gdata.channels){
          channelComponents.push(
              <Channel key={gdata.channels[c].channel['display-name'][0]} title={gdata.channels[c].channel['display-name'][0]} icon={gdata.channels[c].channel['icon'].src}
                       stream={streams[gdata.channels[c].channel.id]} handler={props.clickHandler}
                       programs={gdata.channels[c].programs} w_start={gdata.window_start} w_end={gdata.window_end}
                       duration={duration}
              />
          )
        }
        return channelComponents;
    };

    const doGuide = () => {
        guideData()
            .then( d => renderGuide(d))
            .then( guide => reorderGuide(guide))
            .then( guide => {
                globalState.updateLive({guide: guide, loading: false})
            });
    };

    const shouldRefreshGuide = () => {
        let current_time = new Date();
        let window_start = new Date(w_start * 1000);
        const THITRY_MINUTES = 30 * 60 * 1000;
        return current_time - window_start > THITRY_MINUTES;
    };

    const checkRefresh = () =>
        (refresh_timeout = setTimeout(() => {
            if (shouldRefreshGuide()) {
                console.log('Guide refreshed');
                doGuide();
            }
            checkRefresh();
        }, 60000));

    useEffect( () => {
        axios.get("https://thedwellings.tv/exapi/streams")
            .then( r => {
                return r.data
            })
            .then(data => {
                setStreams(data)})
            .catch( e => {
                console.log(e)
            })
    }, [])

    useEffect( () => {
        globalState.updateApp({stream: streams[Object.keys(streams)[0]]});
        doGuide();
        checkRefresh();
        return () => {
            clearTimeout(refresh_timeout);
        };
    }, [streams])

    return (
        live.loading ?
            <Box direction="column" gap="none" fill="horizontal">
            <Box align="center" margin="medium" fill><BarLoader color="#6ebe44"  width={"30%"} /></Box>
        </Box>
            :
        <Box direction="column" gap="none" fill="horizontal">
            <TimeRow w_start={live.w_start} duration={duration}/>
            {live.guide}
        </Box>
    )
};

export default Guide;
