import React from "react";
import "../../style.css";
import {Box, TextInput, Heading, Button, Text, Layer, Image, List} from 'grommet';
import {Close, Play} from "grommet-icons";
import {Link} from "react-router-dom";

let vodItems = require('./vod.json');

const SeriesBadge = (props) => (
    <Box className="badge" background="brand" round>
        <Text size="16px">Series</Text>
    </Box>
);

const VodDetails = (props) => {
    return(
        <Layer
            position="center" modal onClickOutside={() => props.clickHandler(null)}>
            <Box width="xlarge" pad="large" direction="row-responsive" gap="medium" overflow={{"vertical":"scroll"}}>
                <Box width="1/3" className="vod-poster" alignContent="center" fill gap="small" margin={{"bottom":"50px"}}>
                    <Image src={props.item.image_url} fit="contain"/>
                    <Link to={`/vod/player?url=${props.item.stream_url}`}>
                        <Button icon={<Play />} label="Play" size="small" fill hoverIndicator primary as={"footer"}/>
                    </Link>
                    <Button icon={<Close />} label="Close" size="small" onClick={() => props.clickHandler(null)} plain as="footer"/>
                </Box>
                <Box width="2/3" fill>
                    <h2>{props.item.title}</h2>
                    {typeof props.item.description !== 'undefined' ?
                        <Text margin={{'vertical': '10px'}}>{props.item.description}</Text> :
                        null}
                    {props.item.type !== "series" ? <Text>Duration: {props.item.duration} mins</Text> : null}
                    {props.item.type === "series" ? <List
                        data={props.item.episodes}
                        primaryKey={"title"}
                        pad={{ left: "small", right: "none" }}
                        action={event => (
                            <Link to={`/vod/player?url=${event.stream_url}`}>
                                <Button icon={<Play />} size="small" hoverIndicator />
                            </Link>
                        )}
                    /> : null}
                </Box>
            </Box>
        </Layer>
    )};

const VodItem = (props) => (
    <Box onClick={() => props.handler(props.item)} className="vod-poster" flex={false} margin="small">
        <Box background={{"image": `url(${props.item.image_url})`, "size":"cover"}} className="vod-poster"/>
        <Box background="dark-1" pad={{"horizontal":"10px"}} className="vod-title">
            {props.item.type === "series" ? <SeriesBadge/> : null}
            <Heading responsive level={5} alignSelf="center">{props.item.title}</Heading>
        </Box>
    </Box>
);


class VODApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stream: "https://5e6cea03e25b6.streamlock.net/live/WCTVDT.stream/playlist.m3u8",
            playing: false,
            detailsOpen: false,
            activeItem: null,
            items: null
        };

    }

    detailsHandler = (item) => {
        if(!this.state.detailsOpen){
            this.setState({activeItem:item, detailsOpen: true});
        } else {
            this.setState({detailsOpen: false});
        }
    };

    clickHandler = (s) => {
        this.setState({stream: s, playing: true});
    };

    searchItems = (kws) => {
        kws = kws.toLowerCase();
        let kwsArray = kws.split(' ');
        this.renderItems(kwsArray)
    };

    renderItems = (kws = null) => {
        let itemComponents = [];
        if(kws){
            for(let item in vodItems.items){
                if(kws.some( kw => vodItems.items[item].title.toLowerCase().includes(kw)) || kws.some( kw => vodItems.items[item].description.toLowerCase().includes(kw)) || kws.some( kw => vodItems.items[item].category.toLowerCase().includes(kw))) {
                    itemComponents.push(
                        <VodItem handler={this.detailsHandler} item={vodItems.items[item]}/>
                    )
                }
            }
        }else {
            for (let item in vodItems.items) {
                itemComponents.push(
                    <VodItem key={Math.random()} handler={this.detailsHandler} item={vodItems.items[item]}/>
                )
            }
        }
        this.setState({items: itemComponents});
    };

    componentDidMount() {
        this.renderItems()
    }

    render() {
        return (
            <Box margin={{"top": "xlarge"}} gap="medium" justify="center" flex={false}>
                {this.state.detailsOpen ? <VodDetails item={this.state.activeItem} player={this.clickHandler} clickHandler={this.detailsHandler}/> : null}
                <Box pad={"small"} gap="medium" responsive  direction={"row-responsive"} width={"large"}>
                    <Box width="medium" responsive>
                        <TextInput placeholder="search ..." onChange={ e => this.searchItems(e.target.value)}/>
                    </Box>
                    {/*<FilterMenu />*/}
                </Box>
                <Box flex direction="row-responsive" margin={{"horizontal":"auto"}} wrap>
                    {this.state.items}
                </Box>
            </Box>
        );
    }
}

export default VODApp;
