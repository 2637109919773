import {Box} from "grommet";
import {BarLoader} from "react-spinners";
import ReactPlayer from "react-player";
import React, {useEffect, useState} from "react";
import useGlobalState from "../../hooks/useGlobalState";

const VodPlayer = (props) => {
    const [url, setUrl] = useState();
    const globalState = useGlobalState();
    const ga = globalState.state.app.ga;


    useEffect(() => {
        let params = new URLSearchParams(props.location.search);
        let qUrl = params.get('url');
        setUrl(qUrl);
    }, [props]);

    return (
        url ?
        <Box fill>
        <ReactPlayer controls={true}  playing light
                     className='react-player' width='100%' onError={ e => console.log(e)}
                     height="100%" url={url} onPlay={ () => ga.startPlaying("vod", url)} progressInterval={10000}
                     onProgress={ e => ga.onProgress("vod", e)}
                     onEnded={ () => ga.stopPlaying("vod", url)} onPause={ () => ga.stopPlaying("vod", url)}/>
    </Box> : <Box align="center" margin="medium" fill><BarLoader color="#6ebe44"  width={"30%"} /></Box>
    )};

export default VodPlayer;
