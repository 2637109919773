import React, {useEffect, useState} from "react";
import ReactTooltip from 'react-tooltip'
import { Box, Text, Image, Heading } from "grommet";
import {useMediaQuery} from 'react-responsive'

const Program = (props) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const id = Math.random().toString();
    const start = new Date(props.data.start * 1000);
    const end = new Date(props.data.stop * 1000);
    const w_start = new Date(props.w_start* 1000);
    const w_end = new Date(props.w_end* 1000);

    const [visible, setVisible] = useState(false);
    const [width, setWidth] = useState();

    const duration = () => {
        let duration;
        if (start < w_start) {
            duration = end - w_start;
        } else if (start >= w_end) {
            duration = 0
        } else if (end > w_end && start < w_end) {
            duration = w_end - start;
        } else {
            duration = end - start;
        }
        return(percentOfWindow(duration));
    };

    const percentOfWindow = (duration) => {
        let percent = (Math.round(duration / props.duration) / 60000) * 100;
        if(percent > 1){setVisible(true)}
        return(percent.toString() + "%");
    };

    useEffect( () => {
        setWidth(duration());
    }, [props]);

    return (
        visible &&
        <Box height="xsmall" justify={"center"}
             width={width} className={props.cn}
             border={{"color": "#000", "radius": "10px", "size": "1px"}}
             round
             data-tip data-for={id} data-tip-disable={isTabletOrMobile} overflow={"hidden"}>

            <Heading level="4" responsive alignSelf="center" className="program-title">{props.title}</Heading>
            <ReactTooltip id={id} backgroundColor={'#6ebe44'} className="tool-tip"
                          data-delay-hide='1000'>
                <Box>
                    {typeof props.data.icon !== 'undefined' ?
                        <Image src={props.data.icon.src} fit="contain" width="300px"/> :
                        null}
                    <h2>{props.title}</h2>
                    {typeof props.data['sub-title'] !== 'undefined' ?
                        <em margin={{'vertical': '5px'}}>{props.data['sub-title'].text}</em> :
                        null}
                    {typeof props.data.desc !== 'undefined' ?
                        <Text margin={{'vertical': '10px'}}>{props.data.desc.text}</Text> :
                        null}
                    <Text>{start.toLocaleTimeString()} - {end.toLocaleTimeString()}</Text>
                </Box>
            </ReactTooltip>
        </Box>
    )
};

export default Program;
