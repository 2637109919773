import {Box} from "grommet";
import React from "react";

const TimeBox = (props) => (
    <Box flex border={{"side":"left"}} pad={{"left": "5px"}} {...props}/>
);

class TimeRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            w_start: new Date(this.props.w_start * 1000)
        }
    }

    addMinutes(date, minutes) {
        let d = new Date(date.getTime() + minutes*60000);
        return d.toLocaleTimeString()
    }

    render(){
    return(
        <Box height="20px" direction={"row"} flex className={"timeRow"}>
            <Box width="75px"/>
            <TimeBox>{this.state.w_start.toLocaleTimeString()}</TimeBox>
            <TimeBox>{this.addMinutes(this.state.w_start, 30)}</TimeBox>
            {this.props.duration > 60 ?
            <TimeBox>{this.addMinutes(this.state.w_start, 60)}</TimeBox> : null}
            {this.props.duration > 90 ?
            <TimeBox>{this.addMinutes(this.state.w_start, 90)}</TimeBox> : null}

        </Box>
    )}
}

export default TimeRow;
