import React, {createContext, useContext, useReducer} from "react"
import DGA from "../utils/gaUtils";

const GlobalStateContext = createContext(null);

const UPDATE_APP = 'UPDATE_APP';
const UPDATE_LIVE = 'UPDATE_LIVE';
const UPDATE_VOD = 'UPDATE_VOD';

const initialState = {
    app: {
        currentApp: null,
        loggedIn: false,
        loading: true,
        initialLoad: true,
        favorites: [],
        stream: null,
        now: new Date(),
        user: null,
        ga: new DGA()
    },
    live: {
        guide: null,
        loading: true,
        window_start: null,
        window_end: null
    },
    vod: {
        playing: false,
        detailsOpen: false,
        activeItem: null
    }
};

const globalStateReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_APP:
            return {
                ...state,
                app: {...state.app, ...action.payload.item}
            };
        case UPDATE_LIVE:
            return {
                ...state,
                live: {...state.live, ...action.payload.item}
            };
        case UPDATE_VOD:
            return {
                ...state,
                vod: {...state.vod, ...action.payload.item}
            };
        default:
            return state;
    }
};

export const GlobalStateProvider = ({ children}) => {
    const [state, dispatch] = useReducer(globalStateReducer, initialState);
    return (
        <GlobalStateContext.Provider value={[state, dispatch]}>
            {children}
        </GlobalStateContext.Provider>
    )
};

const useGlobalState = () => {
    const [state, dispatch] = useContext(GlobalStateContext);

    const updateApp = (item) => {
        dispatch({
            type: UPDATE_APP,
            payload: {
                item
            }
        });
    };
    const updateLive = (item) => {
        dispatch({
            type: UPDATE_LIVE,
            payload: {
                item
            }
        });
    };
    const updateVod = (item) => {
        dispatch({
            type: UPDATE_VOD,
            payload: {
                item
            }
        });
    };

    return {
        updateApp,
        updateLive,
        updateVod,
        state
    };
};

export default useGlobalState;
