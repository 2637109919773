import React from "react";
import ReactPlayer from 'react-player';
import "../../style.css";
import Guide from './guide';
import {Box } from 'grommet';
import useGlobalState from "../../hooks/useGlobalState";

const LiveApp = (props) => {
    const globalState = useGlobalState();
    let app = globalState.state.app;
    let ga = globalState.state.app.ga;

    const clickHandler = (s) => {
        ga.stopPlaying();
        globalState.updateApp({stream: s});
    };

    const player = <ReactPlayer url={app.stream} playing muted controls={true} className='react-player' width='100%'
                                height="100%" onPlay={ () => ga.startPlaying("live", app.stream)} progressInterval={10000}
                                onProgress={ e => ga.onProgress("live", e)}
                                onEnded={ () => ga.stopPlaying("live", app.stream)} onPause={ () => ga.stopPlaying("live", app.stream)}/>;

    return (
        <Box>
            <Box className="player-wrapper">
                {player}
            </Box>
            <Box fill overflow={{"vertical":"scroll"}}>
                <Guide clickHandler={clickHandler} favs={app.favorites}/>
            </Box>
        </Box>
    );
};

export default LiveApp;
